import ScreenSizeDetector from 'screen-size-detector';
import Splide from '@splidejs/splide';

const mainSliderOptions = {
  type: 'loop',
  pagination: false,
  perPage: 1,
  arrows: false,
  autoWidth: true,
  // focus: 'center',
  rewind: true,
  rewindByDrag: true,
  updateOnMove: true,
  gap: -160,
  focus: 'center',
  speed: 1200,
  flickPower: 1,
  dragMinThreshold: 1,

  breakpoints: {
    768: {
      gap: -80,
    },
    1439: {
      rewind: false,
      drag: true,
      perPage: 5,
      clones: 4,
    }
  },
};

const thumbnailsSliderOptions = {
  type: 'loop',
  focus: 'center',
  pagination: false,
  autoWidth: true,
  // perMove: 7,
  speed: 800,
  // perPage: 7,
  updateOnMove: true,
  dragMinThreshold: {
    mouse: 4,
    touch: 10,
  },
  breakpoints: {
    1439: {
      // perMove: undefined,
      speed: 1000,
      drag: true,
      clones: 4,
    }
  },
}

const initSlider = (mainClass, tabsClass) => {

  const main = new Splide(mainClass, mainSliderOptions);

  var thumbnails = new Splide(tabsClass, thumbnailsSliderOptions);

  main.sync(thumbnails);
  main.mount();
  thumbnails.mount();
  return [main, thumbnails]
}

const defaultOptions = {
  widthDefinitions: {
    mobile: {
      min: 0,
      max: 768,
      inclusion: "[]",
      onEnter: () => {
        const [main] = initSlider('.tv-hosts-tablet-and-mobile', '.heroes-tabs-tablet-and-mobile');
        const slidesList = main.Components.Slides;
        let slidesNeighbors = [slidesList.getAt(1).slide, slidesList.getAt(-1).slide];

        if (window.location.host.split('.')[0] === 'videos') {
          main.refresh();
        }

        let slidesNeighbors2 = [slidesList.getAt(2).slide, slidesList.getAt(-2).slide];
        slidesNeighbors2.forEach((itm, i) => {
          itm.style.opacity = 1;
          itm.classList.add('tv-hosts__slide_overlay');
        })

        slidesList.getAt(0).slide.querySelector('img').style.transform = 'translate(31px, 0px)';

        slidesList.getAt(-1).slide.querySelector('img').style.transform = 'translate(4px, -13px)';
        slidesList.getAt(1).slide.querySelector('img').style.transform = 'translate(5px, 0px)';

        slidesNeighbors2[1].querySelector('img').style.transform = 'translate(16px, 9px)';
        slidesNeighbors2[0].querySelector('img').style.transform = 'translate(5px, 0px)';

        main.on('active', (slide) => {
          if (slide.isClone) return;

          slidesNeighbors2.forEach((itm) => {
            itm.style.opacity = 0;
            const img = itm.children[0].children[0];
            img.style = '';
            itm.classList.remove('tv-hosts__slide_overlay');
          })
          // slidesNeighbors.forEach((itm) => {
          //   itm.querySelector('img').style.transform = '';
          // })
          slidesNeighbors2 = [slidesList.getAt(slide.index + 2).slide, slidesList.getAt(slide.index - 2).slide];
          slidesNeighbors = [slidesList.getAt(slide.index + 1).slide, slidesList.getAt(slide.index - 1).slide];
          slidesNeighbors2.forEach((itm) => {
            itm.style.opacity = 1;
            const img = itm.children[0].children[0];
            img.style = '';
            itm.classList.add('tv-hosts__slide_overlay');
          })

          switch (slide.index) {
            case 0:
              slidesList.getAt(0).slide.querySelector('img').style.transform = 'translate(31px, 0px)';

              slidesList.getAt(-1).slide.querySelector('img').style.transform = 'translate(4px, -13px)';
              slidesList.getAt(1).slide.querySelector('img').style.transform = 'translate(5px, 0px)';

              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(16px, 9px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(5px, 0px)';
              return;
            case 1:
              slidesList.getAt(1).slide.querySelector('img').style.transform = 'translate(26px, 15px)';

              slidesNeighbors[1].querySelector('img').style.transform = 'translate(-12px, 4px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(19px, 9px)';

              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(17px, -2px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(12px, -7px)';
              return;
            case 2:
              slidesList.getAt(2).slide.querySelector('img').style.transform = 'translate(23px, 15px)';

              slidesNeighbors[1].querySelector('img').style.transform = 'translate(-14px, 6px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(18px, 12px)';

              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(34px, 17px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(11px, -12px)';
              return;
            case 3:
              slidesList.getAt(3).slide.querySelector('img').style.transform = 'translate(21px, 7px)';

              slidesNeighbors[1].querySelector('img').style.transform = 'translate(-12px, 3px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(29px, 12px)';

              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(24px, 9px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(5px, 4px)';
              return
            case 4:
              slidesList.getAt(4).slide.querySelector('img').style.transform = 'translate(33px, 7px)';

              slidesNeighbors[1].querySelector('img').style.transform = 'translate(-12px, 3px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(31px, 8px)';

              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(26px, 4px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(9px, 0px)';
              return
            case 5:
              slidesList.getAt(5).slide.querySelector('img').style.transform = 'translate(29px, 4px)';

              slidesNeighbors[1].querySelector('img').style.transform = 'translate(-3px, 10px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(29px, 12px)';

              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(24px, 9px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(5px, 4px)';
              return
            case 6:
              slidesList.getAt(6).slide.querySelector('img').style.transform = 'translate(33px, 4px)';

              slidesNeighbors[1].querySelector('img').style.transform = 'translate(-6px, 3px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(26px, 5px)';

              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(24px, 9px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(10px, 10px)';
              return
            case 7:
              slidesList.getAt(7).slide.querySelector('img').style.transform = 'translate(33px, 4px)';

              slidesNeighbors[1].querySelector('img').style.transform = 'translate(-6px, 3px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(26px, 5px)';

              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(24px, 9px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(10px, 10px)';
              return
            case 8:
              slidesList.getAt(8).slide.querySelector('img').style.transform = 'translate(35px, 8px)';

              slidesNeighbors[1].querySelector('img').style.transform = 'translate(-2px, 17px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(24px, -1px)';

              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(26px, -3px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(12px, 6px)';
              return
            case 9:
              slidesList.getAt(9).slide.querySelector('img').style.transform = 'translate(23px, 0px)';

              slidesNeighbors[1].querySelector('img').style.transform = 'translate(-6px, 11px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(25px, 1px)';

              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(26px, 4px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(6px, 10px)';
              return
          }
          // slide.update();
        })
      },
    },
    tablet: {
      min: 768,
      max: 1200,
      inclusion: "()",
      onEnter: () => {
        const [main] = initSlider('.tv-hosts-tablet-and-mobile', '.heroes-tabs-tablet-and-mobile');
        const slidesList = main.Components.Slides;
        let slidesNeighbors = [slidesList.getAt(1).slide, slidesList.getAt(-1).slide];

        if (window.location.host.split('.')[0] === 'videos') {
          main.refresh();
        }

        let slidesNeighbors2 = [slidesList.getAt(2).slide, slidesList.getAt(-2).slide];
        slidesNeighbors2.forEach((itm, i) => {
          itm.style.opacity = 1;
          itm.classList.add('tv-hosts__slide_overlay');
        })

        slidesList.getAt(0).slide.style.marginLeft = '-52px';
        slidesList.getAt(-1).slide.querySelector('img').style.transform = 'translateX(5px)';
        slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(76px)';
        slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-18px)';

        main.on('active', (slide) => {
          if (slide.isClone) return;

          slidesNeighbors2.forEach((itm) => {
            itm.style.opacity = 0;
            const img = itm.children[0].children[0];
            img.style = '';
            itm.classList.remove('tv-hosts__slide_overlay');
          })
          // slidesNeighbors.forEach((itm) => {
          //   itm.querySelector('img').style.transform = '';
          // })
          slidesNeighbors2 = [slidesList.getAt(slide.index + 2).slide, slidesList.getAt(slide.index - 2).slide];
          slidesNeighbors = [slidesList.getAt(slide.index + 1).slide, slidesList.getAt(slide.index - 1).slide];
          slidesNeighbors2.forEach((itm) => {
            itm.style.opacity = 1;
            const img = itm.children[0].children[0];
            img.style = '';
            itm.classList.add('tv-hosts__slide_overlay');
          })
          switch (slide.index) {
            case 0:
              slidesList.getAt(0).slide.style.marginLeft = '-35px';
              slidesList.getAt(-1).slide.querySelector('img').style.transform = 'translateX(5px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(76px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-18px)';
              return;
            case 1:
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(0px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(-10px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-56px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(21px)';
              return;
            case 2:
              slidesList.getAt(2).slide.querySelector('img').style.transform = 'translateX(35px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(40px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(-39px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(68px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-55px)';
              return;
            case 3:
              slidesList.getAt(3).slide.querySelector('img').style.transform = 'translateY(2px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(25px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(8px) translateY(5px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(55px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-36px)';
              return
            case 4:
              slidesList.getAt(4).slide.querySelector('img').style.transform = 'translateX(35px) translateY(5px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(5px) translateY(5px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(8px) translateY(5px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(81px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-36px)';
              return
            case 5:
              slidesList.getAt(5).slide.querySelector('img').style.transform = 'translateX(35px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(15px) translateY(5px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(0px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(81px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-36px)';

              return
            case 6:
              slidesList.getAt(6).slide.querySelector('img').style.transform = 'translateX(54px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(18px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(26px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(82px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-5px)';
              return
            case 7:
              slidesList.getAt(7).slide.querySelector('img').style.transform = 'translateY(2px) translateX(40px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(18px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(30px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(82px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(16px)';
              return
            case 8:
              slidesList.getAt(8).slide.querySelector('img').style.transform = 'translateY(2px) translateX(25px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(8px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(30px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(30px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(16px)';
              return
          }
          slide.update();
        })
      },
    },
    desktop: {
      min: 1200,
      max: 10000,
      inclusion: "[]",
      onEnter: () => {
        const [main] = initSlider('.tv-hosts-tablet-and-mobile', '.heroes-tabs-tablet-and-mobile');
        const slidesList = main.Components.Slides;
        let slidesNeighbors = [slidesList.getAt(1).slide, slidesList.getAt(-1).slide];

        if (window.location.host.split('.')[0] === 'videos') {
          main.refresh();
        }

        // TODO: Standartize this
        let slidesNeighbors3 = [slidesList.getAt(3).slide, slidesList.getAt(-3).slide];
        slidesNeighbors3.forEach((itm, i) => {
          itm.style.opacity = 1;
          itm.classList.add('tv-hosts__slide_overlay');
        })

        // neighobrs of slidesNeighbors2
        let slidesNeighbors2 = [slidesList.getAt(2).slide, slidesList.getAt(-2).slide];
        slidesNeighbors2.forEach((itm, i) => {
          itm.style.opacity = 1;
        })

        slidesList.getAt(0).slide.querySelector('img').style.transform = 'translate(43px, 0px)';
        slidesList.getAt(-1).slide.querySelector('img').style.transform = 'translate(27px, 0px)';
        slidesList.getAt(1).slide.querySelector('img').style.transform = 'translate(13px, 29px)';
        slidesNeighbors2[1].querySelector('img').style.transform = 'translate(43px, 52px)';
        slidesNeighbors2[0].querySelector('img').style.transform = 'translate(12px, 44px)';

        slidesNeighbors3[0].querySelector('img').style.transform = 'translate(-15px, 72px)';
        slidesNeighbors3[1].querySelector('img').style.transform = 'translate(43px, 90px)';

        main.on('active', (slide) => {
          if (slide.isClone) return;

          slidesNeighbors3.forEach((itm) => {
            itm.style.opacity = 0;
            const img = itm.children[0].children[0];
            img.style = '';
            itm.classList.remove('tv-hosts__slide_overlay');
          })

          slidesNeighbors2 = [slidesList.getAt(slide.index + 2).slide, slidesList.getAt(slide.index - 2).slide];
          slidesNeighbors3 = [slidesList.getAt(slide.index + 3).slide, slidesList.getAt(slide.index - 3).slide];
          slidesNeighbors = [slidesList.getAt(slide.index + 1).slide, slidesList.getAt(slide.index - 1).slide];

          slidesNeighbors2.forEach((itm) => {
            itm.style.opacity = 1;
          })

          slidesNeighbors3.forEach((itm, i) => {
            itm.style.opacity = 1;
            const img = itm.children[0].children[0];
            img.style = '';
            itm.classList.add('tv-hosts__slide_overlay');
          })

          switch (slide.index) {
            case 0:
              slidesList.getAt(0).slide.querySelector('img').style.transform = 'translate(43px, 0px)';
              slidesList.getAt(-1).slide.querySelector('img').style.transform = 'translate(27px, 0px)';
              slidesList.getAt(1).slide.querySelector('img').style.transform = 'translate(13px, 29px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(43px, 52px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(12px, 44px)';

              slidesNeighbors3[0].querySelector('img').style.transform = 'translate(-15px, 72px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translate(43px, 90px)';
              return;
            case 1:
              slidesList.getAt(1).slide.querySelector('img').style.transform = 'translate(26px, 33px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translate(18px, 41px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(34px, 59px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(17px, 51px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(22px, 28px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translate(12px, 93px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translate(38px, 83px)';
              return;
            case 2:
              slidesList.getAt(2).slide.querySelector('img').style.transform = 'translate(17px, 22px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translate(5px, 30px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(5px, 24px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(13px, 49px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(37px, 20px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translate(-19px, 59px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translate(34px, 59px)';
              return;
            case 3:
              slidesList.getAt(3).slide.querySelector('img').style.transform = 'translate(32px, 12px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translate(11px, 29px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(38px, 43px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(12px, 37px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(15px, 32px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translate(-2px, 62px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translate(56px, 59px)';
              return
            case 4:
              slidesList.getAt(4).slide.querySelector('img').style.transform = 'translate(59px, 27px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translate(11px, 29px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(38px, 47px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(29px, 49px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(20px, 48px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translate(7px, 69px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translate(40px, 85px)';
              return
            case 5:
              slidesList.getAt(5).slide.querySelector('img').style.transform = 'translate(50px, 20px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translate(49px, 43px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(44px, 47px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(31px, 51px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(25px, 48px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translate(16px, 70px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translate(62px, 91px)';
              return
            case 6:
              slidesList.getAt(6).slide.querySelector('img').style.transform = 'translate(56px, 9px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translate(11px, 29px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(38px, 47px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(29px, 49px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(20px, 48px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translate(7px, 69px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translate(35px, 83px)';
              return
            case 7:
              slidesList.getAt(7).slide.querySelector('img').style.transform = 'translate(32px, 12px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translate(12px, 45px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(38px, 43px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(12px, 37px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(15px, 70px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translate(-1px, 76px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translate(46px, 99px)';
              return
            case 8:
              slidesList.getAt(8).slide.querySelector('img').style.transform = 'translate(61px, 10px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translate(40px, 54px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(44px, 11px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(31px, 51px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(51px, 69px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translate(14px, 108px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translate(44px, 96px)';
              return
            case 9:
              slidesList.getAt(9).slide.querySelector('img').style.transform = 'translate(33px, 4px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translate(17px, 61px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translate(39px, 46px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translate(11px, 57px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translate(22px, 83px))';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translate(1px, 103px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translate(49px, 103px)';
              return
          }
          // slide.update();
        })
      },
    },
  },
};

new ScreenSizeDetector(defaultOptions);